import styles from '../SearchResults.module.scss';

import { Link } from 'react-router-dom';

const staticRoot = process.env.REACT_APP_STATIC_ROOT;

function SearchResultsTrainings({ trainings = [] }) {
  if (!trainings.length) {
    return null;
  }

  return (
    <div>
      <h1>Formations</h1>
      <ul>
        {trainings.map((r) => (
          <li key={r.objectID}>
            <Link to={'/formations' + r.path}>
              <span className={styles.inner}>
                <span>
                  <span className={styles.logo} style={{ background: r.training?.color }}>
                    <img src={staticRoot + r.logo} alt={'Logo ' + r.name} />
                  </span>
                </span>
                <span className={styles.content}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: r._highlightResult.name.value,
                    }}
                  />
                  {r.descriptionMatch && (
                    <span
                      className={styles.description}
                      dangerouslySetInnerHTML={{
                        __html: r.descriptionMatch,
                      }}
                    />
                  )}
                  {r.technologyMatch && (
                    <span className={styles.technology}>
                      <span>Technologies abordées : </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: r.technologyMatch,
                        }}
                      />
                    </span>
                  )}
                  {r.programMatch && (
                    <span className={styles.program}>
                      <span>Programme > </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: r.programMatch,
                        }}
                      />
                    </span>
                  )}
                </span>
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SearchResultsTrainings;
