import styles from './Curriculum.module.scss';

import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';

function Curriculum({ curriculum = [] }) {
  return (
    <div>
      <h2>Programme</h2>
      <LinesLoader loading={!curriculum.length} lineHeight={22} lines={10}>
        <div className={styles.days}>
          {curriculum.map((day, i) => (
            <div key={i} className={styles.day}>
              <h3>Jour {i + 1}</h3>
              <div className={styles.chapters}>
                {day.map((item, i) => (
                  <div key={i} className={styles.chapter}>
                    <h4>{item.subject}</h4>
                    <ul>
                      {item.details.map((d, i) => (
                        <li key={i}>{d}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </LinesLoader>
    </div>
  );
}

export default Curriculum;
