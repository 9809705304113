import { Breadcrumb } from '../components/Breadcrumb/Breadcrumb';

const findRoute = (routes, path) => routes.find((r) => path === r.path);

export function pageWithBreadcrumb(routes, route, pathname) {
  return (props) => {
    const Page = route.component;

    const fragments = pathname.split('/').reduce((p, c) => {
      if ('' === c) return p;
      const path = ((p[0] && p[0].path) || '') + '/' + c;
      const route = findRoute(routes, path);

      const name = route ? route.name : c.charAt(0).toUpperCase() + c.slice(1);
      p.push({ path, name });
      return p;
    }, []);

    return (
      <div className="page">
        <Breadcrumb fragments={fragments} />
        <Page {...props} />
      </div>
    );
  };
}
