import { Link } from 'react-router-dom';

import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';

function Handicap({ name, loading }) {
  return (
    <div>
      <h2>Accessibilité aux personnes handicapées</h2>
      <LinesLoader loading={loading} lines={3} bullets>
        <span>
          Nous nous engageons à rendre notre formation {name} accessible aux personnes en situation de handicap. Merci
          de <Link to="/contact">nous contacter</Link> afin que nous puissions réaliser les adaptations nécessaires en
          amont de la formation.
        </span>
      </LinesLoader>
    </div>
  );
}

export default Handicap;
