import styles from './RatingCard.module.scss';

import Satisfaction from '../../../../components/Satisfaction/Satisfaction';
import AsideCard from '../AsideCard/AsideCard';
import AsideCardTitle from '../AsideCard/AsideCardTitle/AsideCardTitle';

function RatingCard({ stats, onAvisClick }) {
  if (!stats?.ratingAvg) {
    return null;
  }

  return (
    <AsideCard className={styles.host}>
      <AsideCardTitle>Avis</AsideCardTitle>
      <Satisfaction stats={stats} onAvisClick={onAvisClick} />
    </AsideCard>
  );
}

export default RatingCard;
