import { axios } from './axios';

export async function sendMail(data) {
  const res = await axios.post('/contact', data);

  if (!res.data.ok) {
    throw new Error('Mail not sent');
  }

  return true;
}
