import styles from './NextSessionList.module.scss';

import NextSession from './NextSession/NextSession';

function NextSessionList({ sessions, path }) {
  return (
    <div className={styles.host}>
      {sessions.map((s, i) => (
        <NextSession key={i} date={s.from} location={s.location} formationPath={path} sessionId={s.id} />
      ))}
    </div>
  );
}

export default NextSessionList;
