import styles from './EstimateForm.module.scss';

import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { sendEstimate } from '../../../api/sendEstimate';
import FormRow from '../../../components/Form/FormRow/FormRow';
import FormSubmit from '../../../components/Form/FormSubmit/FormSubmit';
import { formStatuses } from '../../../components/Form/formStatuses';

export function EstimateForm({ formationPath, session }) {
  const { register, handleSubmit, formState } = useForm();

  const [status, setState] = useState(formStatuses.NONE);

  async function onSubmit(values) {
    setState(formStatuses.LOADING);
    try {
      await sendEstimate({
        ...values,
        formationPath,
        session,
      });
      setState(formStatuses.SUCCESS);
    } catch (error) {
      setState(formStatuses.ERROR);
    }
  }

  return (
    <form className={styles.host} onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2>Demander un devis</h2>
      <div className={styles.flexContainer}>
        <FormRow
          type="text"
          name="name"
          label="Nom complet"
          register={register}
          formState={formState}
          required="Merci de saisir votre nom complet"
        />
        <FormRow
          type="email"
          name="email"
          label="Email"
          register={register}
          formState={formState}
          required="Merci de saisir votre email"
          pattern={{
            value:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            message: 'Le format de votre email est incorrect',
          }}
        />
        <FormRow type="tel" name="phone" label="Téléphone" register={register} />
        <FormRow type="text" name="company" label="Société" register={register} />
        <FormRow type="text" name="role" label="Fonction" register={register} />
        <FormRow type="text" name="nbStudent" label="Nombre de participants" register={register} />
      </div>
      <FormRow
        type="textarea"
        rows="10"
        name="message"
        label="Message"
        placeholder="Dates, niveau des participants..."
        register={register}
      />
      <p>* champs obligatoires</p>
      <FormSubmit status={status} disabled={formState.isSubmitSuccessful} />
    </form>
  );
}

export default EstimateForm;
