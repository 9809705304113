import { parse } from 'query-string';
import { Helmet } from 'react-helmet-async';

import EstimateForm from './EstimateForm/EstimateForm';

function Estimate(props) {
  const params = parse(props.location.search);

  return (
    <div className="page page-estimate">
      <Helmet>
        <title>Demander un devis | formation.tech</title>
        <link rel="canonical" href="https://formation.tech/demander-un-devis" />
      </Helmet>
      <EstimateForm formationPath={params.formation} session={params.session} />
    </div>
  );
}

export default Estimate;
