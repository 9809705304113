import styles from './Logo.module.scss';

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { requestsSelector } from '../../store/selectors/requests';
import { logoString } from './logo-svg';

function Logo() {
  const owlRef = useRef();
  const requests = useSelector(requestsSelector);

  useEffect(() => {
    const owl = owlRef.current;
    const ratio = owl.clientHeight / owl.querySelector('svg').viewBox.baseVal.height;

    // store coordinates of both eyes / set their transformation origin
    const eyes = ['.eye-left', '.eye-right'].map((selector) => {
      const el = owl.querySelector(selector);
      const children = [].filter.call(el.childNodes, (c) => c instanceof SVGElement);
      const cx = +children[0].getAttribute('cx');
      const cy = +children[0].getAttribute('cy');
      const x = cx * ratio + owl.offsetLeft;
      const y = cy * ratio + owl.offsetTop;
      el.style.transformOrigin = `${cx}px ${cy}px 0`;

      return { el, x, y };
    });

    document.addEventListener('mousemove', (e) => {
      if (anim) return;

      eyes.forEach((eye, i) => {
        const x = e.clientX;
        const y = e.clientY;
        const rad = Math.atan2(y - eye.y, x - eye.x);
        const deg = rad * (180 / Math.PI) + 45 + i * 90;

        eye.el.style.transform = `rotate(${deg}deg)`;
      });
    });

    let deg = 0;
    let anim = false;

    function rotateEyes() {
      anim = requestAnimationFrame(() => {
        deg += 10;
        deg = deg % 360;
        eyes.forEach((eye, i) => {
          let eyeDeg = deg + i * 90;
          eye.el.style.transform = `rotate(${eyeDeg}deg)`;
        });

        if (requests) {
          rotateEyes();
        }
      });
    }

    if (requests && !anim) {
      rotateEyes();
    }
    return () => {
      cancelAnimationFrame(anim);
      anim = false;
    };
  }, [requests]);

  return (
    <div>
      <div className={styles.host} ref={owlRef} dangerouslySetInnerHTML={{ __html: logoString }} />
    </div>
  );
}

export default Logo;
