import styles from './NextSession.module.scss';

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link } from 'react-router-dom';

function NextSession({ date, sessionId, location, formationPath }) {
  return (
    <div className={styles.host}>
      <Link to={'/demander-un-devis?formation=' + formationPath + '&session=' + format(date, 'yyyy-MM-dd')}>
        <div className={styles.day}>{format(date, 'dd', { locale: fr })}</div>
        <div className={styles.monthAndLocation}>
          <div>
            <b>{format(date, 'MMMM yyyy', { locale: fr })}</b>
          </div>
          <div>{location}</div>
        </div>
      </Link>
    </div>
  );
}

export default NextSession;

/*
<div>
        {sessions.map((session) => (
          <div
            key={session.id}
            itemScope
            itemType="http://data-vocabulary.org/Event"
          >
            <meta content={'Formation ' + name} itemProp="name" />
            <meta content={'Formation ' + name} itemProp="summary" />
            <meta
              content={dateToJSONLocal(session.from)}
              itemProp="startDate"
            />
            <meta content={dateToJSONLocal(session.to)} itemProp="endDate" />
            <meta content={staticRoot + logo} itemProp="photo" />
            <meta content={session.location} itemProp="location" />
          </div>
        ))}
      </div>
      */
