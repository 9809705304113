import algoliasearch from 'algoliasearch/lite';

const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);

const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

export function searchAlgolia(query) {
  if (!query) return Promise.resolve([]);

  return index.search(query).then((data) => data.hits);
}
