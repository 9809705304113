import stylesCard from '../TrainingCard/TrainingCard.module.scss';
import styles from './TrainingsList.module.scss';

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const staticRoot = process.env.REACT_APP_STATIC_ROOT;

function TrainingsList({ match, trainings = [], currentCategory = {} }) {
  // trailing '/' is important to avoid conflicts like /formations/java and /formations/javascript
  const filteredTrainings = trainings.filter((t) => t.path.startsWith(currentCategory.path + '/'));

  return (
    <div className={styles.host}>
      <Helmet>
        <title>{`${
          currentCategory.name === 'Toutes nos formations' ? 'Nos formations' : `Formations ${currentCategory.name}`
        } | formation.tech`}</title>
        {currentCategory.path && <link rel="canonical" href={'https://formation.tech' + currentCategory.path} />}
      </Helmet>
      {filteredTrainings.map((t) => (
        <Link className={stylesCard.host} key={t.path} to={t.path} data-path={t.path} style={{ zIndex: 0 }}>
          <div className={stylesCard.header} style={{ background: t.color }}>
            <div className={stylesCard.logo}>
              <img src={staticRoot + t.logo} alt={t.name} />
            </div>
            <div className={stylesCard.name}>
              <h1>Formation {t.name}</h1>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default TrainingsList;
