import styles from './Evaluations.module.scss';

import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Fragment, forwardRef } from 'react';
import Rating from 'react-rating';

const commentMap = {
  training: 'La formation',
  trainer: 'Le formateur',
  bestThings: 'Les +',
  afterTraining: 'Commentaire général',
};

function Evaluations({ reviews = [] }, ref) {
  if (!reviews.length) {
    return null;
  }

  const rev = [...reviews].reverse();

  return (
    <div className={styles.host} ref={ref}>
      <h3>Avis</h3>
      <div className={styles.list}>
        {rev.map((r, i) => (
          <div className={styles.item} key={i}>
            <h4>{r.author}</h4>
            <div>
              <Rating
                className={styles.rating}
                start={0}
                stop={5}
                step={1}
                initialRating={r.rating}
                readonly={true}
                emptySymbol={<FontAwesomeIcon icon={faStarEmpty} />}
                fullSymbol={<FontAwesomeIcon icon={faStarFull} />}
              />{' '}
              le {format(parseISO(r.date), 'dd MMMM yyyy', { locale: fr })}
            </div>
            {r.comments && (
              <div>
                {Object.entries(r.comments).map(([key, comment]) => (
                  <p key={key}>
                    <b>{commentMap[key]}</b>
                    <br />
                    {comment.split('\n').map((line, i) => (
                      <Fragment key={i}>
                        {line} <br />
                      </Fragment>
                    ))}
                  </p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default forwardRef(Evaluations);
