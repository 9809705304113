import { trainingDetailsSelector } from './trainings';

const sentenceSplitReg = /[^.!?]+[.!?]+/g;
const hightlightReg = /<em>/;

function compileResultMatch(result) {
  result = { ...result };
  const hightlights = result._highlightResult;

  if (!hightlights) return result;

  // DESCRIPTION MATCHING --------------------------------
  if (hightlights.description && 'full' === hightlights.description.matchLevel) {
    const frags = hightlights.description.value.match(sentenceSplitReg);

    if (frags) {
      for (let frag of frags) {
        if (hightlightReg.test(frag)) {
          result.descriptionMatch = frag;
          return result;
        }
      }
    }
  }

  // TECHNOLOGIE MATCHING --------------------------------
  const technos = hightlights.relatedTechnologies;
  if (technos && 'full' === technos.matchLevel) {
    result.technologyMatch = technos.value;
    return result;
  }

  // PROGRAM MATCHING --------------------------------
  const program = hightlights.program;
  if (program) {
    let dayNumber = 0;
    for (let day of program) {
      dayNumber++;
      for (let item of day) {
        if ('full' === item.subject.matchLevel) {
          result.programMatch = `Jour ${dayNumber} > ${item.subject.value}`;
          return result;
        } else if (item.details) {
          for (let detail of item.details) {
            if ('full' === detail.matchLevel) {
              result.programMatch = `Jour ${dayNumber} > ${item.subject.value} > ${detail.value}`;
              return result;
            }
          }
        }
      }
    }
  }

  return result;
}

export function searchSelector({ search }) {
  return search;
}

export function searchCategoriesSelector(state) {
  const { results } = searchSelector(state);
  return results.filter((r) => r.type === 'category');
}

export function searchTrainingsSelector(state) {
  const { results } = searchSelector(state);
  return results
    .filter((r) => r.type === 'formation')
    .map(compileResultMatch)
    .map((r) => ({
      ...r,
      training: trainingDetailsSelector(state, '/formations' + r.path),
    }));
}
