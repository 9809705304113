import { Link } from 'react-router-dom';

function SearchResultsCategories({ categories = [] }) {
  if (!categories.length) {
    return null;
  }

  return (
    <div>
      <h1>Catégories</h1>
      <ul>
        {categories.map((r) => (
          <li key={r.objectID}>
            <Link to={'/formations' + r.path}>
              <span
                dangerouslySetInnerHTML={{
                  __html: r._highlightResult.name.value,
                }}
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SearchResultsCategories;
