import joursFeries from '@socialgouv/jours-feries';
import {
  addDays,
  addMonths,
  compareAsc,
  endOfMonth,
  isAfter,
  isBefore,
  isEqual,
  nextMonday,
  nextThursday,
  nextTuesday,
  setDate,
  startOfDay,
  subDays,
} from 'date-fns';

function isAfterOrEqual(date, dateToCompare) {
  return isAfter(date, dateToCompare) || isEqual(date, dateToCompare);
}

function isBeforeOrEqual(date, dateToCompare) {
  return isBefore(date, dateToCompare) || isEqual(date, dateToCompare);
}

export function generateDates(name, durationInDays, sessions, now = new Date()) {
  if (!durationInDays) {
    return [];
  }

  // The seed is computed from the training name so training dates
  // will be the same when the component is updated but will
  // be different for each training
  const seed = name
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((sum, charCode) => sum + charCode);

  const dates = [];

  const targetDayCurrentMonth = (seed % endOfMonth(now).getDate()) + 1;
  // If the targetDayOfMonth is before or equal today, we start next month
  const startNextMonth = targetDayCurrentMonth < now.getDate();

  // When the seed is odd we add one month to the first date (so half of training will happen on odd months)
  let offsetMonth = startNextMonth ? (seed % 2) + 1 : 0;

  for (const session of sessions) {
    const from = new Date(session.from);
    dates.push(from);
  }

  for (let i = 0; i < 3 - sessions.length; i++) {
    let month;

    do {
      month = addMonths(now, offsetMonth).getMonth() + 1;
      offsetMonth += 2;
    } while (dates.find((d) => d.getMonth() + 1 === month)); // eslint-disable-line no-loop-func

    const targetDay = (seed % endOfMonth(addMonths(now, offsetMonth - 2)).getDate()) + 1;

    let targetDate = startOfDay(setDate(endOfMonth(addMonths(now, offsetMonth - 2)), targetDay));
    let loop = false;

    do {
      loop = false;
      // avoid week-ends
      if (targetDate.getDay() === 0 || targetDate.getDay() + durationInDays > 6) {
        if (durationInDays === 5) {
          targetDate = nextMonday(targetDate);
        }
        if (durationInDays === 4) {
          targetDate = nextTuesday(targetDate);
        }
        if (durationInDays === 3) {
          targetDate = nextMonday(targetDate);
        }
        if (durationInDays === 2) {
          targetDate = nextThursday(targetDate);
        }
      }

      // Begin or end of week
      if (durationInDays === 3 && targetDate.getDay() !== 1 && targetDate.getDay() !== 3) {
        targetDate = nextMonday(targetDate);
      }
      if (durationInDays === 2 && targetDate.getDay() !== 1 && targetDate.getDay() !== 4) {
        targetDate = nextThursday(targetDate);
      }
      if (durationInDays === 1 && targetDate.getDay() !== 1 && targetDate.getDay() !== 5) {
        targetDate = nextMonday(targetDate);
      }

      // avoid public holidays
      const jourFerieConflict = Object.values(joursFeries(targetDate.getFullYear())).find(
        // eslint-disable-next-line no-loop-func
        (jourFerie) =>
          isAfterOrEqual(jourFerie, targetDate) && isBeforeOrEqual(jourFerie, addDays(targetDate, durationInDays)),
      );

      if (jourFerieConflict) {
        targetDate = addDays(jourFerieConflict, 1);
        loop = true;
      }

      // too close to an existing training dates
      const existingDatesConflict = dates.find(
        // eslint-disable-next-line no-loop-func
        (date) =>
          isAfterOrEqual(date, subDays(targetDate, durationInDays + 10)) &&
          isBeforeOrEqual(date, addDays(targetDate, durationInDays + 10)),
      );

      if (existingDatesConflict) {
        targetDate = addDays(targetDate, 10);
        loop = true;
      }
    } while (loop);

    dates.push(targetDate);
  }

  return dates.sort((a, b) => compareAsc(a, b));
}
