import { createContext, useState } from 'react';

export const HeaderContext = createContext({
  searchFocused: false,
  setSearchFocused: () => {},
  sidebarOpened: false,
  setSidebarOpened: () => {},
  transparent: false,
  setTransparent: () => {},
});

export function HeaderProvider({ children }) {
  const [searchFocused, setSearchFocused] = useState(false);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [transparent, setTransparent] = useState(false);

  const headerService = {
    searchFocused,
    setSearchFocused,
    sidebarOpened,
    setSidebarOpened,
    transparent,
    setTransparent,
  };

  return <HeaderContext.Provider value={headerService}>{children}</HeaderContext.Provider>;
}
