import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';

function Goals({ goals = [] }) {
  return (
    <div>
      <h2>Objectifs pédagogiques</h2>
      <LinesLoader loading={!goals.length} lines={5} bullets>
        <ul>
          {goals.map((g, i) => (
            <li key={i}>{g}</li>
          ))}
        </ul>
      </LinesLoader>
    </div>
  );
}

export default Goals;
