import styles from './RelatedTrainingList.module.scss';

import RelatedTraining from './RelatedTraining/RelatedTraining';

function RelatedTrainingList({ relatedTrainings = [] }) {
  if (!relatedTrainings.length) {
    return null;
  }

  return (
    <div className={styles.host}>
      <h3>Formations associées</h3>
      <div className={styles.list}>
        {relatedTrainings
          .filter((t) => t.isActive)
          .map((t, i) => (
            <RelatedTraining key={t.id} training={t} />
          ))}
      </div>
    </div>
  );
}

export default RelatedTrainingList;
