import styles from './HomeStats.module.scss';

import Satisfaction from '../../../components/Satisfaction/Satisfaction';
import HomeSectionTitle from '../HomeSectionTitle/HomeSectionTitle';

function HomeStatsRate({ stats }) {
  if (!stats.ratingAvg) {
    return null;
  }

  return (
    <section className={styles.host}>
      <HomeSectionTitle>Satisfaction</HomeSectionTitle>
      <Satisfaction stats={stats} />
    </section>
  );
}

export default HomeStatsRate;
