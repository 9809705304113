import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';
import AsideCard from '../AsideCard/AsideCard';
import AsideCardInfo from '../AsideCard/AsideCardInfo/AsideCardInfo';
import AsideCardTitle from '../AsideCard/AsideCardTitle/AsideCardTitle';

function IntraCard({ name, program = [], intraPrice }) {
  return (
    <AsideCard className="intra-card">
      <AsideCardTitle>Intra-Entreprise</AsideCardTitle>
      <LinesLoader loading={!program.length} lineHeight={22} lines={5}>
        <AsideCardInfo>
          <h3>Durée : </h3>
          <span>
            {program.length} {program.length > 1 ? 'jours' : 'journée'} (personnalisable)
          </span>
        </AsideCardInfo>
        <AsideCardInfo>
          <h3>Tarif {format(Date.now(), 'yyyy')} : </h3>
          <span>{intraPrice ? intraPrice + ' euros' : 'Sur demande'}</span>
        </AsideCardInfo>
        <AsideCardInfo>
          <h3>Un programme sur mesure : </h3>
          <p>
            Nous vous contacterons sous 48h avec notre formateur afin de déterminer un programme et une durée adaptée à
            vos besoins.
          </p>
          <p>Le programme de la formation {name} étant entièrement personnalisable.</p>
        </AsideCardInfo>
        <AsideCardInfo>
          <h3>Délais d'accès : </h3>
          <p>Il faut compter au minimum 8 jours entre la prise de contact et le début de la session. </p>
          <p>
            Ce délai peut être rallongé selon les modifications apportées au programme standard, la disponibilité de la
            salle ou du formateur, en cas d'attente de la confirmation de prise en charge par votre OPCO.
          </p>
        </AsideCardInfo>
        <AsideCardInfo>
          <h3>Lieu :</h3>
          <p>Nos sessions intra-entreprise peuvent se dérouler en présentiel ou en classe virtuelle.</p>
          <p>
            Nos avons déjà organisé des sessions de formations en France (y compris en outre-mer), Allemagne, Suisse,
            Belgique, Luxembourg, Suède, Tunisie et la plupart de nos formateurs sont mobiles dans le monde entier.{' '}
          </p>
          <p>
            Nos formateurs sont déjà intervenus à Paris, Lyon, Bordeaux, Marseille, Nantes, Nice (y compris Sophia
            Antipolis), Toulouse, Strasbourg, Montpellier, Rennes, Lille, Grenoble, Angers, Caen, Nîmes, Metz, Dijon,
            Aix-en-Provence, Brest, Rouen, Reims, Saint-Étienne, Nancy, Limoges, Le Havre, Toulon, Clermont-Ferrand, Le
            Mans, Tours, La Rochelle, Orléans, Avignon, Besançon, Annecy, Amiens, Poitiers, Pau, Perpignan, Mulhouse,
            Bayonne, Cannes, Chambéry, Dunkerque, Lorient, Colmar, Belfort, Fort-de-France (Martinique), Point-à-Pitre
            (Guadeloupe), Saint-Denis (La Réunion), Charleroi, Liège, Namur, Mons, La Louvière, Tournai, Berne,
            Fribourg, Lausanne, Sion, Neuchâtel, Genève, Delémont ainsi que dans des dizaines d'autres villes.
          </p>
        </AsideCardInfo>
        <AsideCardInfo>
          <h3>Langues :</h3>
          <span>
            La formation {name} peut être animée en français mais aussi comme d'autres langues comme l'anglais. Merci de{' '}
            <Link to="/contact">nous contacter</Link> afin de confirmer les langues disponibles.
          </span>
        </AsideCardInfo>
      </LinesLoader>
    </AsideCard>
  );
}

export default IntraCard;
