import styles from './NotFound.module.scss';

import { Helmet } from 'react-helmet-async';

export default function NotFound() {
  return (
    <div className={'page ' + styles.host}>
      <Helmet>
        <title>Page non trouvée | formation.tech</title>
      </Helmet>
      <h1>404</h1>
      <p>Cette page n'existe pas</p>
    </div>
  );
}
