import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';

function Audience({ audience = [], loading }) {
  return (
    <div>
      <h2>Public</h2>
      <LinesLoader loading={loading} lines={3} bullets>
        <ul>
          {audience.map((a, i) => (
            <li key={i}>{a}</li>
          ))}
        </ul>
      </LinesLoader>
    </div>
  );
}

export default Audience;
