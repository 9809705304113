import styles from './RelatedTraining.module.scss';

import { Link } from 'react-router-dom';

const staticRoot = process.env.REACT_APP_STATIC_ROOT;

function RelatedTraining({ training }) {
  const style = { backgroundColor: training.color };

  return (
    // <div >
    <Link to={'/formations' + training.path} className={styles.host} style={style}>
      <span className={styles.logo}>
        <img src={staticRoot + training.logo} alt={'Logo ' + training.name} />
      </span>
      <strong>{training.name}</strong>
    </Link>
    // </div>
  );
}

export default RelatedTraining;
