import styles from './EstimateButton.module.scss';

import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function EstimateButton({ path }) {
  return (
    <Link className={styles.host} to={'/demander-un-devis?formation=' + path}>
      Demander un devis
      <FontAwesomeIcon icon={faFileAlt} />
    </Link>
  );
}

export default EstimateButton;
