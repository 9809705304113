import styles from './CategoryDropdown.module.scss';

import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

function CategoryDropdown({ className, categories = [], currentCategory = {} }) {
  const [isOpen, setOpen] = useState(false);
  const hostRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (!hostRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const selectedCategory = currentCategory.path
    ? categories.find((c) => c.path === currentCategory.path)
    : categories[0];

  return (
    <div
      ref={hostRef}
      className={classnames(className, styles.host, { [styles.isOpen]: isOpen })}
      onClick={() => setOpen(!isOpen)}
    >
      <div className={styles.control}>
        <div className={styles.placeholder}>{selectedCategory?.name}</div>
        <div className={styles.arrow}></div>
      </div>
      <div className={styles.menu}>
        {categories.map((c) => (
          <Link
            key={c.path}
            className={classnames(styles.option, { [styles.isSelected]: c === selectedCategory })}
            to={c.path}
          >
            {c.name}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CategoryDropdown;
