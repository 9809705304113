import Contact from './pages/Contact/Contact';
import Estimate from './pages/Estimate/Estimate';
import Home from './pages/Home/Home';
import LegalNotice from './pages/LegalNotice/LegalNotice';
import Trainings from './pages/Trainings/Trainings';

/* Defaults:
  menu -> false
  breadcrumb -> true
*/

export const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
    menu: false, // obviously, we use the logo instead,
    breadcrumb: false,
  },
  {
    path: '/formations',
    component: Trainings,
    name: 'Nos formations',
    menu: true,
    breadcrumb: false,
  },
  // {
  //   path:      '/experts',
  //   component: BlankComponent,
  //   name:      'Experts',
  //   menu:      true
  // },
  // {
  //   path:      '/financement',
  //   component: BlankComponent,
  //   name:      'Financement',
  //   menu:      true
  // },
  {
    path: '/demander-un-devis',
    component: Estimate,
    name: 'Demander un devis',
    menu: false,
    breadcrumb: false,
  },
  {
    path: '/mentions-legales',
    component: LegalNotice,
    name: 'Mentions légales',
    menu: false,
  },
  {
    path: '/contact',
    component: Contact,
    name: 'Contact',
    menu: false, // static since it's a button,
  },
];

export const navlinks = routes
  .filter((r) => r.menu)
  .map((r) => {
    const index = r.path.indexOf(':');
    if (~index) {
      return { ...r, path: r.path.slice(0, index - 1) };
    } else return r;
  });
