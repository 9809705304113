import styles from './ContactForm.module.scss';

import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { sendMail } from '../../../api/sendMail';
import FormRow from '../../../components/Form/FormRow/FormRow';
import FormSubmit from '../../../components/Form/FormSubmit/FormSubmit';
import { formStatuses } from '../../../components/Form/formStatuses';

function ContactForm() {
  const { register, handleSubmit, formState } = useForm();
  const [status, setState] = useState(formStatuses.NONE);

  async function onSubmit(values) {
    setState(formStatuses.LOADING);
    try {
      await sendMail(values);
      setState(formStatuses.SUCCESS);
    } catch (error) {
      setState(formStatuses.ERROR);
    }
  }

  return (
    <form className={styles.host} onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2>Nous contacter</h2>
      <div className={styles.flexContainer}>
        <FormRow
          type="text"
          name="name"
          label="Nom complet"
          register={register}
          formState={formState}
          required="Merci de saisir votre nom complet"
        />
        <FormRow
          type="email"
          name="email"
          label="Email"
          register={register}
          formState={formState}
          required="Merci de saisir votre email"
          pattern={{
            value:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            message: 'Le format de votre email est incorrect',
          }}
        />
        <FormRow type="tel" name="phone" label="Téléphone" register={register} />
        <FormRow
          type="textarea"
          rows="10"
          name="message"
          label="Message"
          register={register}
          formState={formState}
          required="Merci de saisir votre message"
        />
      </div>
      <p>* champs obligatoires</p>
      <FormSubmit status={status} disabled={formState.isSubmitSuccessful} />
    </form>
  );
}

export default ContactForm;
