import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import LoadingBar from './components/LoadingBar/LoadingBar';
import Sidebar from './components/Sidebar/Sidebar';
import { pageWithBreadcrumb } from './hocs/pageWithBreadcrumb';
import NotFound from './pages/NotFound/NotFound';
import { routes } from './routes';

function App() {
  const location = useLocation();
  const { listen } = useHistory();
  const locationKey = location.pathname.split('/')[1];

  listen(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div id="app">
      <LoadingBar />
      <Sidebar />
      <Header />
      <main>
        <TransitionGroup>
          <CSSTransition key={locationKey} timeout={400} classNames="fade">
            <Switch location={location}>
              {routes.map((r, i) => {
                const Component =
                  false === r.breadcrumb ? r.component : pageWithBreadcrumb(routes, r, location.pathname);
                return <Route key={i} path={r.path} exact={r.exact} component={Component} />;
              })}
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </main>
      <Footer />
    </div>
  );
}

export default App;
