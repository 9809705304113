import styles from './FormSubmit.module.scss';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FormSubmit({ disabled, status }) {
  const buttonValue = {
    NONE: 'Envoyer',
    LOADING: <FontAwesomeIcon spin icon={faCircleNotch} />,
    SUCCESS: 'Envoyé !',
    ERROR: "Erreur pendant l'envoi, écrivez nous à hello@formation.tech",
  };

  return (
    <button className={styles.host} type="submit" disabled={disabled}>
      {buttonValue[status]}
    </button>
  );
}

export default FormSubmit;
