import styles from './Search.module.scss';

import SearchForm from './SearchForm/SearchForm';
import SearchResults from './SearchResults/SearchResults';

function Search() {
  return (
    <div className={styles.host}>
      <SearchForm />
      <SearchResults />
    </div>
  );
}

export default Search;
