import styles from './FormRow.module.scss';

import classnames from 'classnames';

function FormRow({
  name,
  formState,
  label,
  type,
  required = false,
  pattern = undefined,
  className,
  register,
  ...rest
}) {
  let inputElement;

  if (required) {
    label += ' *';
  }

  if (type === 'textarea') {
    inputElement = <textarea {...register(name, { required, pattern })} {...rest}></textarea>;
  } else {
    inputElement = <input type={type} {...register(name, { required, pattern })} {...rest} />;
  }

  return (
    <div
      className={classnames(styles.host, className, {
        [styles.hasErrors]: formState?.errors[name],
      })}
    >
      <label>{label}</label>
      {inputElement}
      <div className={styles.formErrors}>{formState?.errors[name]?.message}</div>
    </div>
  );
}

export default FormRow;
