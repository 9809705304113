import styles from './Sidebar.module.scss';

import classnames from 'classnames';
import { useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { navlinks } from '../../routes';
import { HeaderContext } from '../Header/HeaderContext';

function Sidebar() {
  const { sidebarOpened, setSidebarOpened } = useContext(HeaderContext);

  useEffect(() => {
    function handleMouseUp() {
      setSidebarOpened(false);
    }

    if (sidebarOpened) {
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (sidebarOpened) {
        document.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [sidebarOpened, setSidebarOpened]);

  return (
    <div className={classnames(styles.host, { [styles.opened]: sidebarOpened })}>
      <div className={styles.header}>
        <Link to="/" className={styles.logo}>
          <img src="/img/logo-owl.svg" alt="Logo formation.tech" />
        </Link>
      </div>
      <nav>
        {navlinks.map((l) => (
          <NavLink key={l.path} to={l.path}>
            {l.name}
          </NavLink>
        ))}
        <NavLink to="/contact">Contact</NavLink>
      </nav>
    </div>
  );
}

export default Sidebar;
