import styles from './LoadingBar.module.scss';

import { useSelector } from 'react-redux';

import { requestsSelector } from '../../store/selectors/requests';

function LoadingBar() {
  const requests = useSelector(requestsSelector);

  if (!requests) {
    return null;
  }

  return (
    <div className={styles.host}>
      <div className={styles.indeterminate} />
    </div>
  );
}

export default LoadingBar;
