import styles from './Footer.module.scss';

import classnames from 'classnames';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className={classnames(styles.host, 'no-select')}>
      <div className={styles.inner}>
        <div className={styles.logo}>
          <div className={styles.terms}>
            <p>bioub up / formation.tech</p>
            <p className={styles.datadock}>Déclaration d'activité 11 75 55047 75</p>
          </div>
        </div>
        <div className={styles.links}>
          <Link to="/formations">Formations</Link>
          <Link to="/contact">Contactez-nous</Link>
          <Link to="/mentions-legales">Mentions légales</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
