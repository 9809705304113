import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../api/fetchTrainings';
import { trainingDetailsSelector, trainingsSelector } from '../selectors/trainings';

export const fetchTrainings = createAsyncThunk(
  'fetchTrainings',
  async (path, { getState }) => {
    const state = getState();
    let { results } = trainingsSelector(state);

    if (!results.length) {
      results = await api.fetchTrainingList();
    }

    const training = results.find((t) => t.path === path);

    if (training && !training.expanded) {
      const trainingDetails = await api.fetchTrainingByPath(path);

      const trainingIdx = results.indexOf(training);

      results = [...results];

      results[trainingIdx] = {
        ...trainingDetails,
        ...results[trainingIdx],
        expanded: true,
      };
    }

    return results;
  },
  {
    condition: (path, { getState }) => {
      const state = getState();
      const { results, loading } = trainingsSelector(getState());
      const training = trainingDetailsSelector(state, path);

      if (loading) {
        // Already in progress, don't need to re-fetch
        return false;
      }

      if (training?.expanded) {
        // This training already loaded, don't need to re-fetch
        return false;
      }

      if (!training && results.length) {
        // We are a training list page, don't need to re-fetch
        return false;
      }
    },
  },
);
