import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';

function Prerequisites({ prerequisites = [] }) {
  return (
    <div>
      <h2>Pré-requis</h2>
      <LinesLoader loading={!prerequisites.length} lines={2} bullets>
        <ul>
          {prerequisites.map((p, i) => (
            <li key={i}>{p}</li>
          ))}
        </ul>
      </LinesLoader>
    </div>
  );
}

export default Prerequisites;
