import styles from './TrainingCard.module.scss';

import classnames from 'classnames';
import { addDays, format, setHours } from 'date-fns';
import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { LinesLoader } from '../../../components/LinesLoader/LinesLoader';
import Audience from './Audience/Audience';
import Curriculum from './Curriculum/Curriculum';
import EstimateButton from './EstimateButton/EstimateButton';
import Evaluations from './Evaluations/Evaluations';
import Goals from './Goals/Goals';
import Handicap from './Handicap/Handicap';
import InterCard from './InterCard/InterCard';
import IntraCard from './IntraCard/IntraCard';
import LastUpdate from './LastUpdate/LastUpdate';
import Monitoring from './Monitoring/Monitoring';
import Prerequisites from './Prerequisites/Prerequisites';
import RatingCard from './RatingCard/RatingCard';
import RelatedTrainingList from './RelatedTrainingList/RelatedTrainingList';
import Resources from './Resources/Resources';
import TechnologyCard from './TechnologyCard/TechnologyCard';
import { generateDates } from './generateDates';

const staticRoot = process.env.REACT_APP_STATIC_ROOT;

function TrainingCard({ training }) {
  const className = classnames(styles.host, styles.bigCard);
  const evalRef = useRef(null);

  function onAvisClick() {
    console.log('onAvisClick');

    window.scrollBy({
      top: evalRef.current.getBoundingClientRect().top - 64 /* header height */,
      behavior: 'smooth',
    });
  }

  const dates = generateDates(training.name, training.program?.length, training.sessions);

  const sessions = dates.map((d) => ({ from: d, location: 'Paris ou classe virtuelle' }));

  /** @type {import('schema-dts').WithContext<import('schema-dts').Product>} */
  const productSchema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: `Formation ${training.name}`,
    url: `https://formation.tech${training.path}`,
    image: staticRoot + training.logo,
    description: training.description,
    brand: {
      '@type': 'Brand',
      name: 'formation.tech',
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: 'EUR',
      price: training.price || training.program?.length * 660,
      availability: 'InStock',
      validFrom: format(new Date(), 'yyyy-MM-dd'),
      priceValidUntil: format(new Date(), 'yyyy-MM-dd'),
    },
  };

  if (training.stats?.ratingAvg) {
    productSchema.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: training.stats.ratingAvg,
      reviewCount: training.stats.reviewCount,
      bestRating: 5,
      worstRating: 0,
    };
  }

  if (training.reviews?.length) {
    productSchema.review = training.reviews.map((r) => {
      const review = {
        '@type': 'Review',
        datePublished: r.date,
        author: {
          '@type': 'Person',
          name: r.author,
        },
        reviewRating: {
          '@type': 'Rating',
          ratingValue: r.rating,
          bestRating: 5,
          worstRating: 0,
        },
      };

      if (r.comments) {
        review.reviewBody = Object.values(r.comments).join('\n');
      }

      return review;
    });
  }

  /** @type {import('schema-dts').WithContext<import('schema-dts').EducationEvent[]>} */
  const eventsSchema = sessions.map((s) => ({
    '@context': 'http://schema.org',
    '@type': 'EducationEvent',
    eventAttendanceMode: 'http://schema.org/MixedEventAttendanceMode',
    eventStatus: 'http://schema.org/EventScheduled',
    name: 'Formation ' + training.name,
    description: training.description,
    image: staticRoot + training.logo,
    url: `https://formation.tech${training.path}`,
    maximumVirtualAttendeeCapacity: 8,
    maximumPhysicalAttendeeCapacity: 8,
    startDate: setHours(s.from, 9),
    endDate: setHours(addDays(s.from, training.program?.length - 1), 17),
    location: [
      {
        '@type': 'Place',
        name: 'formation.tech',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Paris ou classe virtuelle',
          addressCountry: 'FR',
        },
      },
    ],
    performer: {
      '@type': 'Person',
      name: 'Formateur Expert formation.tech',
    },
    organizer: {
      '@type': 'Organization',
      name: 'formation.tech',
      url: 'https://formation.tech',
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: 'EUR',
      price: training.price || training.program?.length * 660,
      availability: 'InStock',
      validFrom: format(new Date(), 'yyyy-MM-dd'),
      priceValidUntil: format(s.from, 'yyyy-MM-dd'),
      url: `https://formation.tech${training.path}`,
    },
  }));

  return (
    <div className={className} data-path={training.path}>
      <Helmet>
        <title>Formation {training.name} | formation.tech</title>
        <link rel="canonical" href={`https://formation.tech${training.path}`} />
        <script type="application/ld+json">{JSON.stringify([productSchema, ...eventsSchema])}</script>
      </Helmet>
      <div className={styles.header} style={{ background: training.color }}>
        <div className={styles.logo}>
          <img src={staticRoot + training.logo} alt={'Logo ' + training.name} />
        </div>
        <div className={styles.name}>
          <h1>Formation {training.name}</h1>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div className={styles.main}>
            <LinesLoader loading={!training.description}>
              <p className={styles.description}>{training.description}</p>
            </LinesLoader>

            <Goals goals={training.goals} />
            <Prerequisites prerequisites={training.prerequisites} />
            <Audience audience={['Développeurs', 'Chefs de projets']} loading={!training.description} />
            <Curriculum curriculum={training.program} />
            <Resources loading={!training.description} />
            <Monitoring loading={!training.description} />
            <Handicap name={training.name} loading={!training.description} />
          </div>

          <aside className={styles.aside}>
            <EstimateButton path={training.path} />
            <RatingCard stats={training.stats} onAvisClick={onAvisClick} />
            <InterCard
              name={training.name}
              path={training.path}
              program={training.program}
              inter={training.inter}
              sessions={sessions}
              price={training.price}
            />
            <IntraCard name={training.name} program={training.program} intraPrice={training.intraPrice} />
            <TechnologyCard name={training.name} technologies={training.technologies} />
          </aside>
        </div>
        <Evaluations reviews={training.reviews} ref={evalRef} />
        <RelatedTrainingList relatedTrainings={training.relatedFormations} />
        <LastUpdate lastUpdatedAt={training.lastUpdatedAt} />
      </div>
    </div>
  );
}

export default TrainingCard;
