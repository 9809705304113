import { createReducer } from '@reduxjs/toolkit';

import { search } from '../actions/search';

const initialState = {
  query: null,
  results: [],
  loading: false,
};

export const searchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(search.pending, (state, action) => {
      state.loading = true;
      state.query = action.meta.arg;
    })
    .addCase(search.fulfilled, (state, action) => {
      state.loading = false;
      state.results = action.payload;
    })
    .addCase(search.rejected, (state) => {
      state.loading = false;
      state.results = [];
    });
});
