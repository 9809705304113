import styles from './HomeLanding.module.scss';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { HeaderContext } from '../../../components/Header/HeaderContext';

function HomeLanding() {
  const hostRef = useRef();
  const canvasRef = useRef();
  useEffect(() => {
    let particle;
    try {
      const Particle = require('particleground-light').default;
      particle = new Particle(canvasRef.current, {
        dotColor: '#ffffff',
        lineColor: '#ffffff',
        minSpeedX: 0.3,
        minSpeedY: 0.3,
        lineWidth: 0.5,
        particleRadius: 4,
      });
    } catch (e) {
      // ssr
    }

    return () => {
      particle.destroy();
    };
  }, []);

  // Make the header transparent above this component
  const { setTransparent } = useContext(HeaderContext);
  useEffect(() => {
    const ref = hostRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setTransparent(entry.isIntersecting);
      },
      { rootMargin: '-64px' },
    );
    observer.observe(ref);
    return () => {
      observer.unobserve(ref);
      setTransparent(false);
    };
  }, [setTransparent]);

  return (
    <div className={styles.host} ref={hostRef}>
      <div className={styles.particles}>
        <div ref={canvasRef} />
      </div>

      <div className={styles.text}>
        <h1>Ensemble, innovons.</h1>
        <p>Nous vous accompagnons de la conception au développement de vos applications web et mobile.</p>
        <Link to="/formations">
          Nos formations
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
    </div>
  );
}

export default HomeLanding;
