import styles from './Breadcrumb.module.scss';

import { faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

export function Breadcrumb({ fragments = [] }) {
  if (!fragments.length) {
    return (
      <div className={styles.host + ' no-select'}>
        <Link to="/" aria-label="Page d'accueil">
          <FontAwesomeIcon icon={faHome} />
        </Link>
      </div>
    );
  }

  const currentPage = fragments.pop().name;

  return (
    <div className={styles.host + ' no-select'}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              fragments.map((f, i) => ({
                '@type': 'ListItem',
                position: i + 1,
                name: f.name,
                item: `https://formation.tech${f.path}`,
              })),
              {
                '@type': 'ListItem',
                position: fragments.length,
                name: currentPage,
              },
            ],
          })}
        </script>
      </Helmet>
      <Link to="/" aria-label="Page d'accueil">
        <FontAwesomeIcon icon={faHome} />
      </Link>
      <FontAwesomeIcon icon={faAngleRight} />
      {fragments.map((l) => (
        <span key={l.path}>
          <Link to={l.path}>{l.name}</Link>
          <FontAwesomeIcon icon={faAngleRight} />
        </span>
      ))}
      <span>{currentPage}</span>
    </div>
  );
}
