import { createReducer } from '@reduxjs/toolkit';

import { fetchCategories } from '../actions/categories';

const initialState = {
  results: [],
  loading: false,
};

export const categoriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCategories.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.results = action.payload;
    })
    .addCase(fetchCategories.rejected, (state) => {
      state.loading = false;
      state.results = [];
    });
});
