const initialState = 0;

export function requestsReducer(state = initialState, { type }) {
  if (type.endsWith('/pending')) {
    return state + 1;
  }

  if (type.endsWith('/fulfilled') || type.endsWith('/rejected')) {
    return state - 1;
  }

  return state;
}
