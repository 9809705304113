import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';

function Monitoring({ loading }) {
  return (
    <div>
      <h2>Modalités d’évaluation</h2>
      <LinesLoader loading={loading} lines={3} bullets>
        <ul>
          <li>Questions orales ou écrites (QCM)</li>
          <li>Exercices pratiques</li>
          <li>Auto-positionnement en amont et aval de la formation</li>
        </ul>
      </LinesLoader>
    </div>
  );
}

export default Monitoring;
