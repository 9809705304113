import { createReducer } from '@reduxjs/toolkit';

import { fetchStats } from '../actions/stats';

const initialState = {
  ratingAvg: null,
  reviewCount: null,
  loading: false,
};

export const statsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchStats.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchStats.fulfilled, (state, action) => {
      state.loading = false;
      state.ratingAvg = action.payload.ratingAvg;
      state.reviewCount = action.payload.reviewCount;
    })
    .addCase(fetchStats.rejected, (state) => {
      state.loading = false;
    });
});
