import styles from './LastUpdate.module.scss';

import { format } from 'date-fns';

function LastUpdate({ lastUpdatedAt }) {
  if (!lastUpdatedAt) {
    return null;
  }

  const formatedDate = format(new Date(lastUpdatedAt), 'dd/MM/yyyy');
  return <div className={styles.host}>Dernière modification le {formatedDate}</div>;
}

export default LastUpdate;
