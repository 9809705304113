export function categoriesSelector(state) {
  return state.categories;
}

export function categoriesResultsSelector(state) {
  return categoriesSelector(state).results;
}

export function currentCategorySelector(state) {
  return categoriesSelector(state).current;
}
