import styles from './HomeQualiopi.module.scss';

import HomeSectionTitle from '../HomeSectionTitle/HomeSectionTitle';
import { logoQualiopi } from './logoQualiopi';

function HomeQualiopi() {
  return (
    <section className={styles.host}>
      <HomeSectionTitle>Certification Qualité</HomeSectionTitle>
      <p>Notre organisme de formation est certifié Qualiopi depuis le 28/05/2020.</p>
      {logoQualiopi}
      <p>La certification qualité a été délivrée au titre de la catégorie d'actions suivante : actions de formation</p>
      <p>
        <a href="/certificat-B00147.pdf">Télécharger notre certificat</a>
      </p>
    </section>
  );
}

export default HomeQualiopi;
