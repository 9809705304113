import ContentLoader from 'react-content-loader';

export function LinesLoader({
  children,
  loading = false,
  lines = 5,
  fontSize = 16,
  lineHeight = 22,
  bullets = false,
  bulletRadius = 4,
}) {
  const height = lines * lineHeight;

  let shapes = new Array(lines)
    .fill(0)
    .map((_, i) => (
      <rect
        key={i}
        x={bullets ? 20 : 0}
        y={(lineHeight - fontSize) / 2 + i * lineHeight}
        width={Math.floor(Math.random() * 71) + 30 + '%'}
        height={fontSize}
      />
    ));

  if (bullets) {
    shapes = [
      ...shapes,
      ...new Array(lines)
        .fill(0)
        .map((_, i) => (
          <circle
            key={i + lines}
            r={bulletRadius}
            fill="#818785"
            cx={bulletRadius + 1}
            cy={lineHeight / 2 + i * lineHeight}
          />
        )),
    ];
  }

  if (loading) {
    return <ContentLoader style={{ width: '100%', height }}>{shapes}</ContentLoader>;
  }

  return <>{children}</>;
}
