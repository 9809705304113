import styles from './InterCard.module.scss';

import { format } from 'date-fns';

import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';
import AsideCard from '../AsideCard/AsideCard';
import AsideCardInfo from '../AsideCard/AsideCardInfo/AsideCardInfo';
import AsideCardTitle from '../AsideCard/AsideCardTitle/AsideCardTitle';
import NextSessionList from '../NextSessionList/NextSessionList';

function InterCard({ name, inter, program = [], sessions = [], price, path }) {
  return (
    <AsideCard className={styles.host}>
      <AsideCardTitle>Inter-Entreprise</AsideCardTitle>
      {inter === false ? (
        <p>Cette formation n'est pas disponible en inter-entreprise.</p>
      ) : (
        <LinesLoader loading={!program.length} lineHeight={22} lines={5}>
          <AsideCardInfo>
            <h3>Durée : </h3>
            <span>{program.length > 1 ? program.length + ' jours' : program.length + ' journée'}</span>
          </AsideCardInfo>
          <AsideCardInfo>
            <h3>Tarif {format(Date.now(), 'yyyy')} : </h3>
            <span>{price} euros</span>
          </AsideCardInfo>
          <AsideCardInfo>
            <h3>Prochaines sessions :</h3>
            <NextSessionList sessions={sessions} path={path} />
          </AsideCardInfo>
          <AsideCardInfo>
            <h3>Modalités et délais d'accès :</h3>
            <p>
              Nos sessions sont garanties à partir de 2 participants. Afin d'obtenir le meilleur niveau de qualité, nous
              les limitons à 8 participants.
            </p>
            <p>Vous pouvez vous inscrire jusqu'à 48h avant le début d'une session confirmée.</p>
            <p>
              Si la session ne comptait pas suffisamment d'inscrits nous vous proposerons un report à la session
              suivante ou bien de suivre la formation {name} en cours-particulier à un tarif préférentiel.
            </p>
          </AsideCardInfo>
        </LinesLoader>
      )}
    </AsideCard>
  );
}

export default InterCard;
