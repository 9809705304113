import styles from './HomePresentation.module.scss';

function HomePresentation() {
  return (
    <section className={styles.host}>
      <div>
        <h3>Formations sur-mesure</h3>
        <p>
          Nos formations standards ne couvrent pas l'ensemble de vos besoins ? Nos experts vous contactent pour
          construire un programme personnalisé.
        </p>
      </div>

      <div>
        <h3>Accompagnement Post-Formation</h3>
        <p>
          Notre expert vous a convaincu ? Faites vous accompagner dans la mise en place de vos développements pour
          quelques jours à quelques semaines.
        </p>
      </div>

      <div>
        <h3>En France et à l’international</h3>
        <p>
          Nous sommes parisiens mais formons régulièrement dans toute la France et à l’international, par exemple à Aix
          en Provence, Bordeaux, Bruxelles, Genève, Grenoble, Lille, Luxembourg, Lyon, Montpellier, Nantes, Nice (Sophia
          Antipolis), Paris, Rennes, Rouen, Strasbourg, Toulouse ou dans n’importe quelle autre ville sur demande.
        </p>
      </div>
    </section>
  );
}

export default HomePresentation;
