export const metroIcons = {
  2: (
    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 1050 1050">
      <circle fill="#0065AE" cx="500" cy="500" r="500" />
      <path
        fill="#fff"
        d="M676.44 740.95v-88.709H457.76c6.888-30.713 60.133-75.035 87.084-99.75 63.855-57.997 121.62-99.188 121.62-190.01 0-108.05-87.678-160.61-180.76-160.61-71.366 0-118.62 20.991-169.72 65.379l55.717 73.585c12.652-14.335 44.975-48.112 91.434-48.112 57.76 0 87.742 36.776 87.742 82.482 0 51.209-38.023 87.854-73.344 118.63-70.709 61.59-131.47 115.57-144.94 177.29v69.861h343.851"
      />
    </svg>
  ),
  3: (
    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 1050 1050">
      <circle fill="#9F971A" cx="500" cy="500" r="500" />
      <path
        fill="#fff"
        d="M685.43 605.92c0-74.18-44.136-117.93-102.569-129.18v-1.348c56.139-21.041 85.376-63.202 85.376-117.89 0-70.614-61.407-133.66-170-133.66-61.646 0-112.91 17.197-158.63 47.393l36.784 78.104c17.334-15.098 49.443-38.637 98.372-38.637 55.725 0 81.198 29.562 81.198 63.363 0 40.537-32.211 66.135-84.189 66.135h-55.739v87.891h54.93c54.257 0 100.69 19.258 100.69 80.203 0 45.014-38.151 77.943-99.877 77.943-44.985 0-81.832-17.476-104.59-34.355l-40.291 81.59c42.788 24.134 84.03 39.943 152.19 39.943 123.31.07 206.35-74.43 206.35-167.47"
      />
    </svg>
  ),
};
