import styles from './Trainings.module.scss';

import classnames from 'classnames';
import { gsap } from 'gsap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { fetchCategories } from '../../store/actions/categories';
import { fetchTrainings } from '../../store/actions/trainings';
import { categoriesResultsSelector } from '../../store/selectors/categories';
import { trainingsResultsSelector } from '../../store/selectors/trainings';
import NotFound from '../NotFound/NotFound';
import CategoryDropdown from './CategoryDropdown/CategoryDropdown';
import TrainingCard from './TrainingCard/TrainingCard';
import TrainingsList from './TrainingsList/TrainingsList';

const DURATION = 0.4;

function selectAnimElements(bigCardEl) {
  const bigCardLogoEl = bigCardEl.querySelector('[class^=TrainingCard_header_] img');
  const bigCardH1El = bigCardEl.querySelector('[class^=TrainingCard_header_] h1');
  const bigCardContentEl = bigCardEl.querySelector('[class^=TrainingCard_content_]');
  const listEl = document.querySelector('[class^=TrainingsList_host_]');

  const path = bigCardEl.dataset.path;
  const smallCardEl = listEl?.querySelector(`[data-path="${path}"]`);

  return { bigCardLogoEl, bigCardH1El, bigCardContentEl, listEl, smallCardEl };
}

function Trainings({ match }) {
  const [animating, setAnimating] = useState(false);
  const [routesHeight, setRoutesHeight] = useState('auto');
  const dispatch = useDispatch();
  const location = useLocation();
  const { listen } = useHistory();

  listen(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    Trainings.fetchData(dispatch, location.pathname);
  }, [dispatch, location.pathname]);

  const trainings = useSelector(trainingsResultsSelector);
  const categories = useSelector(categoriesResultsSelector);

  const currentTraining = trainings.find((t) => location.pathname.startsWith(t.path));

  const currentCategory = currentTraining
    ? categories.find((c) => c.id === currentTraining.categoryId)
    : categories.find((c) => c.path === location.pathname);

  if (!currentTraining && !currentCategory) {
    return <NotFound />;
  }

  // Build fragments
  let fragments = [];
  if (categories.length && trainings.length && location.pathname.startsWith('/formations')) {
    fragments = location.pathname
      .split('/')
      .slice(1)
      .reduce((acc, segment) => [...acc, (acc[acc.length - 1] ?? '') + '/' + segment], [])
      .map((path) => {
        const category = categories.find((c) => c.path === path);

        if (category) {
          return {
            name: category.name === 'Toutes nos formations' ? 'Formations' : category.name,
            path,
          };
        }

        const training = trainings.find((t) => t.path === path);

        if (training) {
          return {
            name: 'Formation ' + training.name,
            path,
          };
        }

        return {
          name: 'Devis',
          path,
        };
      });
  }

  return (
    <div className="page">
      <div className={styles.navigation}>
        <Breadcrumb fragments={fragments} />
        <CategoryDropdown
          className={styles.categoryDropdown}
          currentCategory={currentCategory}
          categories={categories}
        />
      </div>
      <TransitionGroup
        className={classnames(styles.routes, { [styles.animating]: animating })}
        style={{ height: routesHeight }}
      >
        <CSSTransition
          key={!!currentTraining} // only transition category <-> training
          timeout={DURATION * 1000}
          onEnter={() => {
            setAnimating(true);
            window.scrollTo(0, 0);
          }}
          onEntered={(node) => {
            setAnimating(false);
            setRoutesHeight('auto');
          }}
          onEntering={(node) => {
            if (node?.className.startsWith('TrainingCard_host_')) {
              const bigCardEl = node;

              const { bigCardLogoEl, bigCardH1El, bigCardContentEl, listEl, smallCardEl } =
                selectAnimElements(bigCardEl);

              if (!smallCardEl) {
                return;
              }

              const [listCoord, smallCardCoord, bigCardCoord] = [listEl, smallCardEl, bigCardEl].map((el) =>
                el.getBoundingClientRect(),
              );

              setRoutesHeight(Math.max(bigCardCoord.height, listCoord.height) + 'px');

              smallCardEl.style.opacity = 0;

              /*** CARD ***/
              gsap.set(bigCardEl, {
                transformOrigin: 'top center',
                x: smallCardCoord.x - listCoord.x,
                width: smallCardCoord.width,
              });
              gsap.from(bigCardEl, {
                duration: DURATION,
                y: smallCardCoord.y - bigCardCoord.y,
                ease: 'power1.in', // different timing on x and y to curve
              });
              gsap.to(bigCardEl, {
                x: listCoord.x + listCoord.width / 2 - (bigCardCoord.x + smallCardCoord.width / 2),
                duration: DURATION,
                ease: 'power1.out', // different timing on x and y to curve
              });
              gsap.from(bigCardEl, {
                duration: DURATION,
                height: smallCardCoord.height,
                delay: DURATION / 4,
                ease: 'power1.in', // different timing on x and y to curve
                onComplete: () => {
                  bigCardEl.style.height = null;
                },
              });
              gsap.to(bigCardEl, {
                x: 0,
                width: '100%',
                duration: (DURATION / 4) * 3,
                delay: DURATION / 4,
              });

              /*** LOGO ***/
              gsap.to(bigCardLogoEl, {
                scale: 1.3,
                delay: (DURATION / 4) * 3,
                duration: DURATION,
                ease: 'power1.out',
                onComplete: () => {
                  gsap.to(bigCardLogoEl, {
                    delay: 0,
                    scale: 1,
                    duration: DURATION,
                    ease: 'back.out',
                  });
                },
              });

              /*** TITLE ***/
              gsap.fromTo(
                bigCardH1El,
                {
                  fontSize: '1em',
                },
                {
                  fontSize: '1.6em',
                  duration: DURATION,
                  ease: 'power1.in',
                },
              );

              /*** CONTENT ***/
              gsap.from(bigCardContentEl, {
                height: 0,
                opacity: 0,
                delay: (DURATION / 4) * 1,
                duration: (DURATION / 4) * 3,
                ease: 'power1.in',
                onComplete: () => {
                  bigCardContentEl.style.height = null;
                },
              });
            }
          }}
          onExit={(node) => {
            if (node?.className.startsWith('TrainingCard_host_')) {
              const bigCardEl = node;
              const { bigCardH1El, bigCardContentEl, listEl, smallCardEl } = selectAnimElements(bigCardEl);

              if (!listEl || !smallCardEl) {
                return;
              }

              const [listCoord, smallCardCoord, bigCardCoord] = [listEl, smallCardEl, bigCardEl].map((el) =>
                el.getBoundingClientRect(),
              );

              setRoutesHeight(Math.max(bigCardCoord.height, listCoord.height) + 'px');

              smallCardEl.style.opacity = 0;

              /*** CARD ***/
              gsap.to(bigCardEl, {
                width: smallCardCoord.width,
                x: smallCardCoord.x - listCoord.x,
                duration: DURATION,
                ease: 'power1.in', // different timing on x and y to curve
              });
              gsap.to(bigCardEl, {
                y: smallCardCoord.y - listCoord.y,
                duration: DURATION,
                ease: 'power1.out', // different timing on x and y to curve
              });

              /*** TITLE ***/
              gsap.fromTo(
                bigCardH1El,
                {
                  fontSize: '1.6em',
                },
                {
                  fontSize: '1em',
                  duration: DURATION,
                  ease: 'power1.in',
                },
              );

              /*** CONTENT ***/
              gsap.to(bigCardEl, {
                height: smallCardCoord.height,
                duration: (DURATION / 4) * 3,
                ease: 'power1.in',
              });
              gsap.to(bigCardContentEl, {
                height: 0,
                opacity: 0,
                duration: (DURATION / 4) * 3,
                ease: 'power1.in',
              });
            }
          }}
          onExited={(node) => {
            if (node?.className.startsWith('TrainingCard_host_')) {
              const bigCardEl = node;
              const { smallCardEl } = selectAnimElements(bigCardEl);

              if (!smallCardEl) {
                return;
              }

              smallCardEl.style.opacity = null;
            }
          }}
        >
          <Switch location={location}>
            {trainings.map((t) => (
              <Route key={t.path} path={t.path}>
                <TrainingCard training={t} />
              </Route>
            ))}
            <Route path={match.path + '/:category(.*)?'}>
              {({ match }) => <TrainingsList match={match} trainings={trainings} currentCategory={currentCategory} />}
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

// SSR
Trainings.fetchData = function (dispatch, pathname) {
  return Promise.all([dispatch(fetchCategories()), dispatch(fetchTrainings(pathname))]);
};

export default Trainings;
