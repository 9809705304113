import styles from './SearchForm.module.scss';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { search } from '../../../store/actions/search';
import { HeaderContext } from '../../Header/HeaderContext';

function SearchForm() {
  const elRef = useRef();
  const inputRef = useRef();
  const dispatch = useDispatch();

  const { searchFocused, setSearchFocused } = useContext(HeaderContext);

  useEffect(() => {
    // No need to store a reference to remove the listener, this component
    // won't be destroyed.
    document.addEventListener('mouseup', (e) => {
      const searchFocused = elRef.current.contains(e.target);
      setSearchFocused(searchFocused);
      if (searchFocused) {
        inputRef.current.focus();
      }
    });
  }, [setSearchFocused]);

  return (
    <div className={classnames(styles.host, { [styles.focused]: searchFocused })} ref={elRef}>
      <label>
        <FontAwesomeIcon icon={faSearch} />
      </label>
      <div className={styles.textfield}>
        <input
          type="text"
          aria-label="Recherche"
          ref={inputRef}
          onChange={(e) => dispatch(search(e.currentTarget.value))}
        />
      </div>
    </div>
  );
}

export default SearchForm;
