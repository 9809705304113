import styles from './Header.module.scss';

import { faBars, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';

import { navlinks } from '../../routes';
import Logo from '../Logo/Logo';
import Search from '../Search/Search';
import { HeaderContext } from './HeaderContext';

function Header() {
  const elRef = useRef();
  const { searchFocused, sidebarOpened, setSidebarOpened, transparent } = useContext(HeaderContext);
  const className = classnames(
    styles.host,
    { [styles.searchBoxFocused]: searchFocused, [styles.transparent]: transparent },
    'no-select',
  );

  return (
    <header className={className} ref={elRef}>
      {/* --------- Hamburger icon ------------ */}
      <span onClick={() => setSidebarOpened(!sidebarOpened)} className={styles.sidebarTrigger}>
        <FontAwesomeIcon icon={faBars} />
      </span>

      {/* --------- Logo ------------ */}
      <Link to="/" className={styles.logo} aria-label="Page d'accueil">
        <Logo />
      </Link>

      {/* --------- Nav Left ------------ */}
      <nav className={styles.navLeft}>
        {navlinks.map((l) => (
          <Link key={l.path} to={l.path}>
            {l.name}
          </Link>
        ))}
      </nav>

      {/* --------- Nav Right ------------ */}
      <nav className={styles.navRight}>
        {/* --------- Contact button ------------ */}
        <Link to="/contact" className={styles.contactBtn}>
          <FontAwesomeIcon icon={faEnvelope} />
          Contactez-nous
        </Link>

        {/* --------- Search ------------ */}
        <Search />
      </nav>
    </header>
  );
}

export default Header;
