import { LinesLoader } from '../../../../components/LinesLoader/LinesLoader';

function Resources({ loading }) {
  return (
    <div>
      <h2>Méthodes mobilisées</h2>
      <LinesLoader loading={loading} lines={3} bullets>
        <ul>
          <li>
            Un formateur validé par nos équipes techniques et pédagogiques sera présent pendant toute la durée de la
            formation
          </li>
          <li>
            Pour les formations en présentiel, une salle de formation sera mise à disposition et équipée d'ordinateurs
            portables, d'un accès Wi-Fi, d'un projecteur et d'un tableau blanc. En distanciel la session sera suivie sur
            Zoom ou autre outil de visioconférence sur demande (Teams, Webex, Google Meet...).
          </li>
          <li>Un support de cours en version numérique sera remis à chaque participant</li>
        </ul>
      </LinesLoader>
    </div>
  );
}

export default Resources;
