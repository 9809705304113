import styles from './TechnologyItem.module.scss';

import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import parse from 'semver/functions/parse';

function parseISOAndFormat(dateStr, formatStr) {
  return format(parseISO(dateStr), formatStr, { locale: fr });
}

function TechnologyItem({ technology }) {
  const lastUpdateDate = technology.lastUpdateDate && parseISOAndFormat(technology.lastUpdateDate, 'dd MMM yyyy');

  const { major, minor } = parse(technology.version) || {};

  let versionToShow = '';

  if (major || minor) {
    versionToShow = major ? major : `${major}.${minor}`;
  }

  return (
    <li className={styles.host}>
      {technology.website ? (
        <a href={technology.website} target="_blank" rel="noopener noreferrer">
          {technology.name} {versionToShow}
        </a>
      ) : (
        <span>
          {technology.name} {versionToShow}
        </span>
      )}
      {technology.version && (
        <div className={styles.version}>
          <div>
            Version actuelle : <strong>{technology.version}</strong>
          </div>
          {lastUpdateDate && <div>Mise à jour le {lastUpdateDate}</div>}
        </div>
      )}
    </li>
  );
}

export default TechnologyItem;
