import styles from './SearchResults.module.scss';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { searchCategoriesSelector, searchSelector, searchTrainingsSelector } from '../../../store/selectors/search';
import { HeaderContext } from '../../Header/HeaderContext';
import SearchResultsCategories from './SearchResultsCategories/SearchResultsCategories';
import SearchResultsTrainings from './SearchResultsTrainings/SearchResultsTrainings';

function SearchResults() {
  const { searchFocused } = useContext(HeaderContext);
  const { query, loading } = useSelector(searchSelector);
  const categories = useSelector(searchCategoriesSelector);
  const trainings = useSelector(searchTrainingsSelector);

  if (!query) return <div />;

  return (
    <div className={classnames(styles.host, { [styles.opened]: query, [styles.focused]: searchFocused })}>
      {loading && (
        <div className={styles.loading}>
          <FontAwesomeIcon spin icon={faCircleNotch} />
        </div>
      )}
      <SearchResultsCategories categories={categories} />
      <SearchResultsTrainings trainings={trainings} />
      {!loading && !categories.length && !trainings.length && <p>Aucun résultat</p>}
      <div className={styles.searchByAlgolia}>
        <img src="/img/search-by-algolia.svg" alt="Logo Algolia" />
      </div>
    </div>
  );
}

export default SearchResults;
