import { axios } from './axios';

export async function fetchCategories() {
  const res = await axios.get('/categories/all');

  return [
    {
      name: 'Toutes nos formations',
      path: '/formations',
    },
    ...res.data,
  ];
}
