import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { fetchStats } from '../../store/actions/stats';
import { statsSelector } from '../../store/selectors/stats';
import HomeLanding from './HomeLanding/HomeLanding';
import HomePresentation from './HomePresentation/HomePresentation';
import HomeQualiopi from './HomeQualiopi/HomeQualiopi';
import HomeReferences from './HomeReferences/HomeReferences';
import HomeStats from './HomeStats/HomeStats';

function Home() {
  const stats = useSelector(statsSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    Home.fetchData(dispatch);
  }, [dispatch, stats.ratingAvg, stats.reviewCount]);

  return (
    <div className="page page-home">
      <Helmet>
        <title>formation.tech | Centre de formation dédié aux développeurs</title>
        <link rel="canonical" href="https://formation.tech/" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'EducationalOrganization',
            image: ['https://formation.tech/img/salle1.jpg', 'https://formation.tech/img/salle2.jpg'],
            name: 'formation.tech',
            url: 'https://formation.tech',
            logo: 'https://formation.tech/img/logo-owl@2x.png',
            email: 'hello@formation.tech',
            telephone: '+33 1 75 85 85 15',
            foundingDate: '2012-01-02',
            address: {
              '@type': 'PostalAddress',
              streetAddress: '21 Rue de Saussure',
              postalCode: '75017',
              addressLocality: 'Paris',
              addressRegion: 'Île-de-France',
              addressCountry: 'France',
            },
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: stats.ratingAvg,
              reviewCount: stats.reviewCount,
            },
          })}
        </script>
      </Helmet>
      <HomeLanding />
      <HomePresentation />
      <HomeReferences />
      <HomeStats stats={stats} />
      <HomeQualiopi />
    </div>
  );
}

// SSR
Home.fetchData = function (dispatch) {
  return dispatch(fetchStats());
};

export default Home;
