import styles from './Contact.module.scss';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet-async';

import ContactAddress from './ContactAddress/ContactAddress';
import ContactForm from './ContactForm/ContactForm';
import ContactInfos from './ContactInfos/ContactInfos';

const ContactMap = lazy(() => import('./ContactMap/ContactMap'));

function Contact() {
  return (
    <div className={styles.host}>
      <Helmet>
        <title>Nous contacter | formation.tech</title>
        <link rel="canonical" href="https://formation.tech/contact" />
      </Helmet>
      <div className={styles.col}>
        <ContactAddress />
        <Suspense
          fallback={
            <div className={styles.mapContainer}>
              <FontAwesomeIcon spin icon={faCircleNotch} />
            </div>
          }
        >
          <ContactMap />
        </Suspense>
      </div>
      <div className={styles.col}>
        <ContactInfos />
        <ContactForm />
      </div>
    </div>
  );
}

export default Contact;
