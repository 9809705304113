import { combineReducers } from 'redux';

import { categoriesReducer } from './categories';
import { requestsReducer } from './requests';
import { searchReducer } from './search';
import { statsReducer } from './stats';
import { trainingsReducer } from './trainings';

export const rootReducer = combineReducers({
  categories: categoriesReducer,
  requests: requestsReducer,
  stats: statsReducer,
  search: searchReducer,
  trainings: trainingsReducer,
});
