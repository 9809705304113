import styles from './HomeReferences.module.scss';

import HomeSectionTitle from '../HomeSectionTitle/HomeSectionTitle';

function HomeReferences() {
  return (
    <section className={styles.host}>
      <HomeSectionTitle>Nos références</HomeSectionTitle>
      <ul>
        <li>
          <img src="/img/logos-references/banque-de-france.png" alt="Banque de France" />
        </li>
        <li>
          <img src="/img/logos-references/pixmania.png" alt="Pixmania" />
        </li>
        <li>
          <img src="/img/logos-references/alstom.png" alt="Alstom" />
        </li>
        <li>
          <img src="/img/logos-references/fred-and-farid.jpg" alt="Fred & Farid" />
        </li>
        <li>
          <img src="/img/logos-references/psa.svg" alt="PSA Peugeot Citroën" />
        </li>
        <li>
          <img src="/img/logos-references/sqli-digital-performance.png" alt="SQLI Digital Performance" />
        </li>
        <li>
          <img src="/img/logos-references/thales.svg" alt="Thales" />
        </li>
        <li>
          <img src="/img/logos-references/atos.png" alt="Atos" />
        </li>
        <li>
          <img src="/img/logos-references/orange.svg" alt="Orange" />
        </li>
      </ul>
    </section>
  );
}

export default HomeReferences;
