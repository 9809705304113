export function trainingsSelector(state) {
  return state.trainings;
}

export function trainingsResultsSelector(state) {
  return trainingsSelector(state).results;
}

export function trainingDetailsSelector(state, path) {
  const results = trainingsSelector(state).results;

  if (!results.length) {
    return null;
  }

  return results.find((t) => t.path === path);
}
