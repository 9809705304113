import { axios } from './axios';

export async function fetchTrainingList() {
  const res = await axios.get('/formations/all');
  return res.data;
}

/**
 * @param {string} path
 */
export async function fetchTrainingByPath(path) {
  if (path.startsWith('/formations')) {
    path = path.slice('/formations'.length);
  }

  const res = await axios.get(`/formations/by-path?filter[where][path]=${path}`);
  return res.data;
}
