import styles from './Satisfaction.module.scss';

import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import Rating from 'react-rating';

function Satisfaction({ stats, onAvisClick = () => {} }) {
  let formatedRatingAvg = stats.ratingAvg;

  if (Math.round(stats.ratingAvg) !== stats.ratingAvg) {
    formatedRatingAvg = Number(stats.ratingAvg).toFixed(1);
  }

  return (
    <Fragment>
      <span className={styles.rating}>
        <Rating
          start={0}
          stop={5}
          step={1}
          initialRating={formatedRatingAvg}
          readonly={true}
          emptySymbol={<FontAwesomeIcon icon={faStarEmpty} />}
          fullSymbol={<FontAwesomeIcon icon={faStarFull} />}
        />
      </span>
      {formatedRatingAvg} / 5
      <div className={styles.weight} onClick={onAvisClick}>
        ({stats.reviewCount} avis)
      </div>
    </Fragment>
  );
}

export default Satisfaction;
