import styles from './TechnologyCard.module.scss';

import AsideCard from '../AsideCard/AsideCard';
import AsideCardTitle from '../AsideCard/AsideCardTitle/AsideCardTitle';
import TechnologyItem from './TechnologyItem/TechnologyItem';

function TechnologyCard({ name, technologies = [] }) {
  if (!technologies.length) {
    return null;
  }

  return (
    <AsideCard className={styles.host}>
      <AsideCardTitle>Technologies</AsideCardTitle>
      <p>La formation {name} aborde les technologies ou outils suivants :</p>
      <ul>
        {technologies.map((technology) => (
          <TechnologyItem key={technology.id} technology={technology} />
        ))}
      </ul>
    </AsideCard>
  );
}

export default TechnologyCard;
