import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../api/fetchStats';
import { statsSelector } from '../selectors/stats';

export const fetchStats = createAsyncThunk(
  'fetchStats',
  async () => {
    const data = await api.fetchStats();
    return data;
  },
  {
    condition: (_, { getState }) => {
      const stats = statsSelector(getState());
      if ((stats.ratingAvg && stats.reviewCount) || stats.loading) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);
