import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { metroIcons } from './metro-icons';

function ContactAddress() {
  return (
    <div>
      <h2>Nous rejoindre</h2>
      <address>
        <div className="prepend-icon">
          <span>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </span>
          <span>Onlyburo - 6 Rue Jadin, 75017 Paris</span>
        </div>

        <div className="prepend-icon">
          <span>{metroIcons[2]}</span>
          <span>
            Monceau <small>ou</small> Courcelles
          </span>
        </div>

        <div className="prepend-icon">
          <span>{metroIcons[3]}</span>
          <span>
            Malesherbes <small>ou</small> Wagram
          </span>
        </div>
      </address>
    </div>
  );
}

export default ContactAddress;
