import styles from './HomeSectionTitle.module.scss';

function HomeSectionTitle({ children }) {
  return (
    <h2 className={styles.host}>
      <span>{children}</span>
    </h2>
  );
}

export default HomeSectionTitle;
