import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ContactInfos() {
  return (
    <div>
      <h2>Contactez nous</h2>
      <address>
        <div className="prepend-icon">
          <span>
            <FontAwesomeIcon icon={faPhone} />
          </span>
          <span>01 75 85 85 15</span>
        </div>
        <div className="prepend-icon">
          <span>
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          <span>hello@formation.tech</span>
        </div>
      </address>
    </div>
  );
}

export default ContactInfos;
