import './scss/styles.scss';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { configureStore } from '@reduxjs/toolkit';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { HeaderProvider } from './components/Header/HeaderContext';
import { rootReducer } from './store/reducers';

// https://fontawesome.com/docs/web/use-with/react/use-with#next-js
config.autoAddCss = false;

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// const { store } = configureStore(preloadedState);
const store = configureStore({
  preloadedState,
  reducer: rootReducer,
});

const container = document.getElementById('root');

let root;
const app = (
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <HeaderProvider>
          <App />
        </HeaderProvider>
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
);

if (preloadedState) {
  root = hydrateRoot(container, app);
} else {
  root = createRoot(container);
  root.render(app);
}
